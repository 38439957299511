<template>
  <router-link :to="{ path }" class="custom-btn">
    <BootstrapIcon icon="eye" size="1x" />
  </router-link>
</template>

<script>
export default {
  name: "ViewBtn",
  props: {
    path: { type: String, default: "" },
  },
};
</script>
